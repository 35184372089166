<template>
  <b-sidebar
    id="add-new-tags-sidebar"
    :visible="isAddNewTagsSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-tags-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Tags
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="tagsData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="MFRS"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Transalate -->
          <label for="description-form">Options for translate each region</label>
            <transition-group
                id="description-form"
                name="custom-classes"
                enter-active-class="animate__animated animate__fadeInDown"
                leave-active-class="animate__animated animate__fadeOutUp"
                :duration="0"
            >
                <div
                  v-for="index in countDescription"
                  :key="index"
                >
                  <!-- repeater from -->
                  <b-col cols="12" class="mb-2">
                    <div class="border rounded p-2">
                      <div
                        v-if="arrayDescription.length > 1 && index !== 1"
                        class="d-flex flex-row-reverse py-50 px-25 col-sm-12"
                      >
                        <feather-icon
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeItemDescription(index - 1)"
                        />
                      </div>
                      <b-row>
                        <!-- Language -->
                        <b-col md="12">
                          <b-form-group
                            label="Language"
                            label-for="product-add-region"
                            class="mb-2"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Language"
                              rules="required"
                            >
                              <v-select
                                id="region-edit-region"
                                v-model="arrayDescription[index - 1].region_id"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="languageOptions"
                                :clearable="false"
                                :disabled="index === 1"
                                :selectable="(option) => {
                                  return !arrayDescription.find(x => x.region_id.value === option.value)
                                }"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Name -->
                        <b-col md="12">
                          <b-form-group
                            label="Name"
                            label-for="product-add-title"
                            class="mb-2"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Name"
                              rules="required"
                            >
                              <b-form-input
                                id="product-add-title"
                                v-model="arrayDescription[index - 1].name"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </div>
              </transition-group>
            <b-col
              cols="12"
              class="mt-2"
              >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="primary"
                @click="addNewItemInItemFormDescription"
              >
                Add description form
              </b-button>
            </b-col>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, 
  BFormInput, BFormInvalidFeedback, 
  BButton, BCol, BCardBody, BRow
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'

export default {
  components: {
    BCol,
    BRow,
    BCardBody,
    vSelect,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewTagsSidebarActive',
    event: 'update:is-add-new-tags-sidebar-active',
  },
  props: {
    isAddNewTagsSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    ...mapGetters('app', ['statusOptions', 'languageOptions']),
  },
  methods:{
    addNewItemInItemFormDescription() {
      this.arrayDescription.push({
        name: '',
        description: '',
        region_id: '',
      })
      this.countDescription += 1
    },
    removeItemDescription(index) {
      this.arrayDescription.splice(index, 1)
      this.countDescription -= 1
    },
  },
  setup(props, { emit }) {
    const countDescription = ref(1)
    const arrayDescription =  ref([
          {
          region_id:  {
          label: 'English',
          value: 'en',
          region: 'my',
        },
          name: '',
          description: '',
        },
      ])
    const toast = useToast()
    const blankTags = {
      name: '',
    }

    const tagsData = ref(JSON.parse(JSON.stringify(blankTags)))
    const resetuserData = () => {
      tagsData.value = JSON.parse(JSON.stringify(blankTags))
    }

    const onSubmit = () => {
      const transferData = {
        name: tagsData.value.name,
        metadata: arrayDescription.value
      }
      store.dispatch('product/addTags', transferData)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-tags-sidebar-active', false)
        }).catch(e => {
          toast({
            component: ToastificationContent,
            props: {
              title: e.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      toast,
      tagsData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      countDescription,
      arrayDescription
    }
  },
}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  #add-new-tags-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
  </style>
